// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/routelog`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/routelog/export`

// 获取巡更点列表数据{routeLogId}
const getRouteLogListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/getPointLogByRouteLogId/`

// 获取巡更点列表数据{routeLogId}
const getStartVideoPatrolURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/startVideoPatrol/`

// 接手通知
const executeURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/execute`

// 打点通知
const dotURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/dot`
// 获取一级分类
const getOneLevelURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/getOneLevel`
// 查询二级分类
const getTwoLevelURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/getTwoLevel`

// 异常工单提交
const workOrderURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/workOrder`

// 获取视频地址
const accessDeviceLiveAddressURL = `${API_CONFIG.iotURL}iotThreeDimensionalView/accessDeviceLiveAddress`

// 判断新老工单
const checkNewWorkOrder = `${API_CONFIG.butlerBaseURL}butler/operateOrder/checkNewWorkOrder`

// 工作台用 根据公司id获取 工单树 接口 
const getTreeByRegionIdURL = `${API_CONFIG.taskDispatchURL}dispatch/category/getTreeByRegion`

// 新增工单
const addNewReport = `${API_CONFIG.butlerBaseURL}butler/operateOrder/addNewReport`

// 新增工单
const taskAdd = `${API_CONFIG.origin}task-dispatch/dispatch/task/add`
export {
  getListURL,
  exportListURL,
  getRouteLogListURL,
  getStartVideoPatrolURL,
  dotURL,
  getOneLevelURL,
  getTwoLevelURL,
  workOrderURL,
  executeURL,
  accessDeviceLiveAddressURL,
  checkNewWorkOrder,
  getTreeByRegionIdURL,
  addNewReport,
  taskAdd
}
