<template>
  <div class="routeLog-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-input label="路线名称" v-model="searchParams.name"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input label="巡更人" v-model="searchParams.dealUserName"></v-input>
        <v-select label="巡更类型" v-model="searchParams.patrolMethod" :options="deviceTypeOps"></v-select>
        <v-select label="巡更状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker label="计划开始时间段" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.patrolMethod === 1" text="查看巡更点" type="text" @click="checkPoint(scope.row)"></v-button>
        <v-button
          text="开始视频巡更"
          v-if="(scope.row.appStatus === 1 || scope.row.appStatus === 2 || scope.row.appStatus === 3) && scope.row.patrolMethod === 2"
          type="text"
          permission="videoPatrolling"
          @click="startVideo(scope.row)">
        </v-button>
      </template>
    </list>
    <el-dialog
      title="巡更情况"
      :visible.sync="isVisitor"
    >
      <div class="dialog-content" >
        <table-panel :headers="tableHeaders" :tableData="tableData" maxHeight="500" :hasOperateColumn="false"></table-panel>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { TablePanel } from 'components/bussiness'
import { createAlinkVNode } from 'common/vdom'
import { getListURL, exportListURL, getRouteLogListURL } from './api'
import { statusOps, statusMap, pointStatusMap } from './map'
import { communityParams } from './../map'

export default {
  name: 'routeLog',
  components: {
    TablePanel
  },
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        name: '',
        communityId: '',
        dealUserName: '',
        status: null,
        startTime: '',
        endTime: '',
        patrolMethod: undefined
      },
      communityParams: communityParams,
      statusOps: statusOps,
      isVisitor: false,
      headers: [
        {
          prop: 'routeName',
          label: '巡更线路'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'patrolMethod',
          label: '巡更类型',
          formatter: (row) => {
            return row.patrolMethod === 2 ? '视频巡更' : '线下巡更'
          }
        },
        {
          prop: 'planBeginTime',
          label: '巡更计划开始时间'
        },
        {
          prop: 'actualTime',
          label: '巡更实际开始时间'
        },
        {
          prop: 'dealUserName',
          label: '巡更人'
        },
        {
          prop: 'finishCount',
          label: '已打点/巡更点',
          formatter: (row, prop) => {
              return createAlinkVNode(this, row, prop, {
                  text: `${row.finishCount} / ${row.pointCount}`,
                  cb: this.showInfoDetail
              })
          }
        },
        {
          prop: 'finishTime',
          label: '巡更实际完成时间'
        },
        {
          prop: 'status',
          label: '巡更状态',
          formatter: (val) => statusMap[val.appStatus]
        }
      ],
      tableHeaders: [
        {
          prop: 'pointName',
          label: '巡更点'
        },
        {
          prop: 'status',
          label: '巡更状态',
          formatter: (val) => pointStatusMap[val.appStatus]
        },
      ],
      tableData: [],
      deviceTypeOps: [{
        text: '全部',
        value: undefined
      },
      {
        text: '线下巡更',
        value: 1
      },{
        text: '视频远程巡更',
        value: 2
      }]
    }
  },
  created () {
    if (this.$route.query.name || this.$route.query.status) {
      this.searchParams.name = this.$route.query.name || ''
      this.searchParams.status = this.$route.query.status || null
    }
  },
  methods: {
     // 点击标题显示跳转内容弹框
    showInfoDetail (row) {
      this.isVisitor = true
      this.tableData = []
      this.$axios.get(`${getRouteLogListURL}${row.id}`)
        .then((res) => {
          if (res.status === 100) {
            this.tableData = [...res.data]
          }
        })
    },
    checkPoint (row) {
      this.$router.push({
        name: 'electronic4',
        query: {
          routeLogId: row.id,
          routeId: row.routeId
        }
      })
    },
    startVideo (row) {
      this.$router.push({
        name: 'pathExecutiveForm',
        query: {
          routeLogId: row.id,
          communityId: row.communityId,
          patrolMethod: row.patrolMethod
        }
      })
    }
  }
}
</script>
