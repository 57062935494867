import { generateMapByOpts } from 'common/utils'
const statusOps = [
  {
    text: '全部',
    value: null
  }, {
    text: '未开始',
    value: 0
  }, {
    text: '待处理',
    value: 1
  }, {
    text: '处理中',
    value: 2
  }, {
    text: '已超时',
    value: 3
  }, {
    text: '已完成',
    value: 4
  }, {
    text: '超时完成',
    value: 5
  }, {
    text: '漏巡',
    value: 6
  }]

const statusMap = generateMapByOpts(statusOps)
// 
const orderTypeOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '维修服务',
    value: 1
  },
  {
    text: '园区报事',
    value: 2
  }
]
const orderTypeMap = generateMapByOpts(orderTypeOps)

// 点状态
const pointStatusOps = [
  {
    text: '请选择',
    value: undefined
  },
  {
    text: '未巡',
    value: 0
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '异常',
    value: 2
  },
  {
    text: '漏巡',
    value: 3
  },
]
const pointStatusMap = generateMapByOpts(pointStatusOps)
export {
  statusOps,
  statusMap,
  orderTypeOps,
  orderTypeMap,
  pointStatusOps,
  pointStatusMap,
}
